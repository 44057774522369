export default {
  phone: [
    {
      required: true,
      message: "请输入手机号",
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式",
    },
  ],

  age: [
    {
      required: true,
      message: "请输入年龄",
    },
    {
      pattern: /^(0|[1-9]|[1-9][0-9]|1[0-4][0-9]|150)$/,
      message: "请检查年龄格式，最大150岁",
    },
  ],

  cmpy: [
    {
      required: true,
      message: "请输入工作单位全称",
    },
  ],

  education: [
    {
      required: true,
      message: "请选择学历",
    },
  ],
  educationType: [
    {
      required: true,
      message: "请选择学历形式",
    },
  ],

  college: [
    {
      required: true,
      message: "请输入毕业院校",
    },
  ],
  overSea: [
    {
      required: true,
      message: "请选择海外留学经历",
    },
  ],
  major: [
    {
      required: true,
      message: "请输入所学专业",
    },
  ],
  graduateDate: [
    {
      required: true,
      message: "请选择毕业时间",
    },
  ],

  bankId: {
    required: true,
    message: "请输入社会保障卡开户银行",
  },
  bankAccount: {
    required: true,
    message: "请输入社会保障卡金融账号",
  },
  bankBranch: {
    required: true,
    message: "请输入社会保障卡支行名称",
  },
  bankCode: {
    required: true,
    message: "请输入行号",
  },
};
